<template>
  <div>
    <v-list subheader v-if="associations.length > 0">
      <v-list-item
        v-for="association in associations"
        :key="association.id"
        @click="gotoAssociation(association)"
      >
        <v-list-item-title class="py-2">
          <v-row wrap>
            <v-col cols="4" sm="2">
              <div>
                <v-chip x-small dark color="grey">{{ association.id }}</v-chip>
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <div>
                {{ association.name }}
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <div>
                {{ association.users.length }} / {{ association.max_members }}
              </div>
            </v-col>
          </v-row>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
  props: {
    associations: { type: Array, required: true }
  },
  methods: {
    gotoAssociation(association) {
      this.$router.push({
        name: "association",
        params: { id: association.id }
      });
    }
  }
};
</script>
<style scoped>
.v-list-item--active {
  font-weight: bold;
  background-color: #eceff1;
}
</style>
