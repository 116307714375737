<template>
  <div>
    <h3 class="text-h3">Associations</h3>
    <v-form @submit.prevent="fetch">
      <v-text-field
        required
        label="Filter by association name"
        v-model="prefix"
      />
    </v-form>
    <div class="mb-4 caption">
      <div v-if="loading">Loading...</div>
      <div v-else>
        <div>{{ filteredAssociations.length }} results found</div>
        <AssociationList :associations="filteredAssociations" />
      </div>
    </div>
  </div>
</template>

<script>
import AssociationList from "@/components/AssociationList.vue";
import Backend from "@/services/Backend";

export default {
  components: {
    AssociationList
  },
  data() {
    return {
      prefix: "",
      associations: [],
      loading: false
    };
  },
  computed: {
    filteredAssociations() {
      return this.associations.filter(
        assoc => assoc.name.indexOf(this.prefix) == 0
      );
    }
  },
  mounted() {
    // return this.fetch();
  },
  methods: {
    async fetch() {
      this.loading = true;
      this.associations = [];
      let response = await Backend.getAssociations(this.prefix);
      this.associations = response.data.sort((x, y) =>
        x.name.toLowerCase() < y.name.toLowerCase() ? -1 : 1
      );
      this.loading = false;
    }
  }
};
</script>

<style scoped></style>
